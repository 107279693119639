// стили для кнопок materialUI
button:focus {
  outline: none !important;
}

.mat-button-focus-overlay {
  background-color: transparent !important;
}
// btn кнопки должны быть 32px
.btn {
  min-height: 32px !important;
}

// выравнивание текста по центру у кнопок ссылок
a.btn {
  padding-top: 6px;

  &-cross {
    right: 0;
  }
}

// кнопки с градиентом
.mat-mdc-raised-button {
  --mdc-protected-button-label-text-color: white;
  --mdc-protected-button-container-height: 32px;

  &.mat-btn {
    &-small{
      --mdc-protected-button-container-height: 28px;
      font-size: 11px;
    }

    box-shadow: none !important;
    padding-left: 30px !important;
    padding-right: 30px !important;
    min-width: 7.5rem;
    font-size: 0.85rem;
    border-radius: 2.4rem;

    &-secondary {
      background-color: transparent;
      border-color: var(--mat-custom-color-light-gray);
      color: var(--mat-custom-gray-bolder);
      &:hover {
        color: var(--mat-custom-gray-bolder) !important;
        border-color: var(--mat-custom-color-light-gray);
      }
    }

    &.mat-mdc-outlined-button {
      border-radius: 2.4rem;
      transition: 0.3s;

      &.mat-button-disabled {
        cursor: not-allowed;
      }

      &:hover {
        border-color: var(--mat-custom-color-light-gray);
      }
    }

    &.gray-dark {
      color: var(--mat-custom-color-white);
      background-color: var(--mat-custom-color-white);
    }

    &.btn-disabled {
      opacity: 0.3;
      //color: var(--mat-custom-color-white);
      cursor: not-allowed;
    }
  }
}
// кнопка с градиентом
.mat-mdc-raised-button.mat-btn-main {
  background: rgb(163, 36, 191) !important;
  background: linear-gradient(
    90deg,
    rgba(163, 36, 191, 1) 0%,
    rgba(231, 49, 122, 1) 35%,
    rgba(255, 62, 77, 1) 100%
  ) !important;
  color: var(--mat-custom-color-white);
  &:hover {
    background: rgb(143, 31, 168) !important;
    background: linear-gradient(
      90deg,
      rgba(143, 31, 168, 1) 0%,
      rgba(189, 42, 101, 1) 35%,
      rgba(185, 50, 61, 1) 100%
    ) !important;
    color: var(--mat-custom-color-white) !important;
  }
}
.mat-mdc-raised-button.mat-btn-main.mat-button-disabled {
  opacity: 0.8 !important;
  color: var(--mat-custom-color-white);
  &:hover {
    background: rgb(163, 36, 191) !important;
    background: linear-gradient(
      90deg,
      rgba(163, 36, 191, 1) 0%,
      rgba(231, 49, 122, 1) 35%,
      rgba(255, 62, 77, 1) 100%
    ) !important;
    color: var(--mat-custom-color-white);
  }
}

// кнопки с рамками
.mat-mdc-outlined-button {
  --mdc-outlined-button-container-height: 32px;
  border-radius: 2.4rem !important;
  min-width: 7.5rem;
  font-size: 0.85rem;
  padding-left: 22px !important;
  padding-right: 22px !important;
  line-height: 34px !important;
}
//mat-primary
.mat-mdc-outlined-button.mat-primary {
  border-color: var(--mat-custom-color-blue-indigo) !important;
  &:hover {
    color: var(--mat-custom-color-blue-indigo) !important;
  }
}
.mat-mdc-outlined-button.mat-primary.mat-button-disabled {
  color: var(--mat-custom-color-blue-indigo) !important;
  &:hover {
    color: var(--mat-custom-color-blue-indigo) !important;
  }
}

//mat-accent
.mat-mdc-outlined-button.mat-accent {
  border-color: var(--mat-custom-color-orange-light) !important;
  &:hover {
    color: var(--mat-custom-color-orange-light) !important;
  }
}
.mat-mdc-outlined-button.mat-accent.mat-button-disabled {
  color: var(--mat-custom-color-orange-light) !important;
  &:hover {
    color: var(--mat-custom-color-orange-light) !important;
  }
}

//mat-warn
.mat-mdc-outlined-button.mat-warn {
  border-color: var(--mat-custom-color-red) !important;
  &:hover {
    color: var(--mat-custom-color-red) !important;
  }
}
.mat-mdc-outlined-button.mat-warn.mat-button-disabled {
  color: var(--mat-custom-color-red) !important;
  &:hover {
    color: var(--mat-custom-color-red) !important;
  }
}

// кнопка загрузки файлов,
// на основе mat-stroked-button color="warn">
.upload-button {
  padding-left: 40px !important;
  padding-right: 40px !important;
  .novicon-download {
    margin-left: 2px;
    margin-bottom: 3px;
  }
}

// убираем мерцание после нажатия
.mat-button-ripple {
  display: none;
}

// прозрачная кнопка
.mat-button-base.transparent {
  background-color: transparent !important;
  box-shadow: none !important;
  .mat-icon {
    color: var(--mat-custom-color-light-gray) !important;
  }
}

// бесцветная кнопка для таблицы
.mat-button-base.default-table {
  background-color: transparent !important;
  box-shadow: none !important;
  border: 1px solid var(--mat-custom-color-blue-indigo);
  width: 31px;
  height: 31px;
  span {
    padding: 0px;
  }
  .mat-icon {
    color: var(--mat-custom-color-blue-indigo) !important;
    width: 28px !important;
    height: 33px !important;
  }
}

mat-datepicker-toggle .mat-icon-button {
  height: 100%;
  width: 30px;
  line-height: 30px;
  opacity: 0.7;
}

.mat-button-disabled {
  opacity: 0.6 !important;
}
