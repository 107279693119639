.mat-mdc-radio-button {
  --mdc-radio-state-layer-size: 16px;
  &.mat-primary{
    --mdc-radio-selected-icon-color: #315470;
  }

  &.invalid {
    --mdc-form-field-label-text-color: #f7142f;
    --mdc-radio-unselected-icon-color: #f7142f;
  }

  .mdc-radio{
    width: 16px;
    height: 16px;
  }
  
  .mat-mdc-radio-touch-target {
    width: 16px;
    height: 16px;
  }
  
  .mdc-radio__background {
    width: 16px;
    height: 16px;
  }
  
  .mdc-radio__inner-circle {
    top: 50%;
    left: 50%;
    box-sizing: border-box;
    width: 6px;
    height: 6px;
    transform: scale(0, 0) translate(-50%, -50%);
    border-width: 0;
    border-color: white;
    background: #fff;
  }
  
  .mdc-radio__native-control:checked+.mdc-radio__background .mdc-radio__inner-circle {
    transform: scale(1) translate(-50%, -50%);
  }
  
  .mdc-radio .mdc-radio__native-control:enabled:checked+.mdc-radio__background .mdc-radio__outer-circle {
    background: var(--mat-custom-color-blue-indigo);
  }
}