.mat-field {
  border: 1px solid var(--mat-custom-color-blue-indigo);
  height: 28px;
  border-radius: 6px;
  box-sizing: border-box;
  position: relative;
  width: 100%;

  --mat-form-field-container-height: 28px;
  --mat-form-field-container-vertical-padding: 0;
  --mdc-filled-text-field-container-color: var(--mat-custom-color-white);
  --mat-form-field-container-text-size: 13px;
  --mdc-form-field-label-text-size: 13px;
  --mat-form-field-error-text-color: var(--mat-custom-color-red);

  .mdc-text-field--filled.mdc-text-field--disabled {
    border-radius: 16px;
  }

  .mat-mdc-form-field-text-prefix {
    margin-right: 6px;
    display: flex;
  }

  // плейсхолдер
  .mat-mdc-form-field-label-wrapper {
    bottom: -6px;
    left: 7px;
    .mat-mdc-form-field-label {
      .dateRange-placeholder {
        padding-right: 27px;
      }
    }
  }

  // линия под контролом
  .mat-mdc-form-field-underline, .mdc-line-ripple {
    display: none;
  }

  .mat-mdc-form-field-focus-overlay {
    background: none;
  }
  
  &.dateRange {
    // плейсхолдер
    .mat-mdc-form-field-label-wrapper {
      .mat-mdc-form-field-label {
        padding-right: 27px;
      }
    }
  }
}
//состояние фокуса
mat-form-field.mat-mdc-focused {
  // плейсхолдер
  .mat-mdc-form-field-label-wrapper {
    .mat-mdc-form-field-label {
      display: none;
    }
  }
}

// не валиднный контролл
::ng-deep .mat-mdc-form-field-invalid {
  border: 1px solid var(--mat-custom-color-red) !important;
}

.form-field-warning-comment {
  color: var(--mat-custom-color-red);
  font-size: 0.75rem;
}


.mat-mdc-form-field-error {
  font-size: 13px;

  &.mat-mdc-form-field-bottom-align::before {
    content: none;
  }
}