/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@include mat.core();

@import "@angular/cdk/overlay-prebuilt.css";
@import "styles/variables";
@import "styles/mixins";
@import 'styles/animations';
@import 'styles/material-font';

$my-primary: mat.define-palette($mat-indigo, 500);
$my-accent:  mat.define-palette($mat-orange, 500);
$my-warn:    mat.define-palette($mat-red, 500);
$tst-level: mat.define-typography-level(
  $font-size: 13px,
  $letter-spacing: normal,
);
$my-typography: mat.define-typography-config(
  $font-family: 'Verdana',
  $button: $tst-level
);
@include mat.typography-hierarchy($my-typography);
$my-theme: mat.define-light-theme((
 color: (
   primary: $my-primary,
   accent: $my-accent,
   warn: $my-warn,
 ),
 typography: $my-typography,
 density: 0,
));
@include mat.all-component-themes($my-theme);

@import 'styles/material-form';
@import 'styles/material-input';
@import 'styles/material-button';
@import 'styles/material-checkbox';
@import 'styles/material-datepicker';
@import 'styles/material-radio';
@import 'styles/material-dialog';

::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

::-webkit-scrollbar-thumb {
  background: #D9D9D9;;
  border-radius: 20px;
}

::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 20px;
}

body {
  margin: 0;
}

*[hidden] {
  display: none !important;
}

input::placeholder {
  color: var(--mat-custom-color-light-gray);
}

.container {
  @include response-rule("max-width", 500px);
  @include response-rule("min-width", 320px);
  @include response-rule("margin", auto);   
}

.title {
  font-weight: 700;
  color: var(--mat-custom-color-blue-indigo);
  @include response-rule("font-size", 17px); 
  @include response-rule("margin-bottom", 24px); 
}

.subTitle {
  @include response-rule("font-size", 13px); 
  @include response-rule("line-height", 20px); 
  @include response-rule("margin-bottom", 5px);
}

.return {
  margin-top: 20px;
  font-size: 13px;
  line-height: 14px;
  color: var(--mat-custom-color-blue-indigo);
  cursor: pointer;
}

.bold {
  font-weight: 700;
}

.row {
  display: flex;
  align-items: center;
  
  &.space-between {
    justify-content: space-between;
  }
}

.flex {
  display: flex;
}

.text {

  &-right {
    text-align: right;
  }

  &-left {
    text-align: left;
  }

  &-small {
    font-size: 12px;
    margin: 0;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.txt-success {
  color: var(--mat-custom-color-blue-light);
}

.text-lightgray {
  color: var(--mat-custom-color-light-gray);
}

.txt-warning {
  color: var(--mat-custom-icon-color-warning);
}

.width-full {
  width: 100%;
}

.ml-1 {
  margin-left: 10px;
}

.pt-20 {
  padding-top: 20px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pl-20 {
  padding-left: 20px;
}

.pr-20 {
  padding-right: 20px;
}

.leaflet-popup-content-wrapper{
  background-color: #CE0058;
  box-shadow: 0 4px 40px #4848481a;
  color: #fff;

  .leaflet-popup-content {
    margin: 6px 12px;
    margin-right: 18px;
  }
}

.leaflet-popup-tip {
  background-color: #CE0058;
  box-shadow: 0 4px 40px #4848481a;
}

.leaflet-container a.leaflet-popup-close-button {
  width: 19px;
  height: 15px;
  font: 16px / 18px Tahoma, Verdana, sans-serif;
  color: #fff;
}

.leaflet-attribution-flag {
  display: none !important;
}

.map-popup {

  &-name {
    margin-bottom: 5px;
    font-weight: bold;
    font-size: 11px;
  }

  &-priсe {
    font-size: 10px;
    font-weight: bold;
    display: flex;
    align-items: center;
  }
}