.dialog-body {
  padding: 16px;
  position: relative;
  text-align: center;

  .title {
    margin: 0;
  }

  .mat-mdc-dialog-content {
    --mat-dialog-content-padding: 0;
    --mdc-dialog-supporting-text-color: var(--mat-custom-color-darkest);
    font-size: 13px;
    line-height: 20px;
  }
}

.dialog-footer.mat-mdc-dialog-actions {
  justify-content: end;
  background-color: var(--mat-custom-color-gray-brightest);
  margin-left: -16px;
  margin-right: -16px;
  padding-left: 36px;
  padding-right: 36px;
}

.dialog-close {
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
}
