mat-input-container.ng-valid.ng-touched {
  .mat-mdc-input-placeholder.mat-mdc-empty.mat-mdc-float {
    transform: translateY(-100%) scale(0.75);
  }
}

mat-form-field {
  .mat-mdc-form-field {
    &-flex {
      align-items: center;
      height: 100%;
    }
  }
  .mat-mdc-text-field-wrapper {
    padding-left: 8px;
    border-radius: 8px;
    
    .mat-mdc-form-field-infix {
      width: auto;
      min-height: 100%;
      display: flex;
      align-items: center;
  
      .mat-mdc-form-field-input-control {
        height: 100%;
      }
    }
  }
  

  // инпут внутри контролла
  .mat-mdc-input-element {
    box-sizing: inherit;

    &.form-control-measured {
      padding-right: 30px;
    }

    &.mat-mdc-chip-input {
      margin: 0;
      width: 100%;
    }
  }

  // скрываем контроллы у инпута типа число
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  // скрываем контроллы у инпута типа число для мозилы
  input[type='number'] {
    -moz-appearance: textfield;
  }
}

//состояние фокуса
mat-form-field.mat-mdc-focused {
  // инпут внутри контролла
  .mat-mdc-input-element {
    caret-color: var(--mat-custom-color-light-gray) !important;
  }
}

//добавляем фон для состояния дизейбла
mat-form-field.mat-mdc-form-field-disabled {
  background-color: var(--mat-custom-color-background);
  color: var(--mat-custom-color-light-gray);
}

// убирем RequiredMarker (*) пока не работает hideRequiredMarker
.mat-mdc-placeholder-required {
  display: none;
}

.cdk-visually-hidden {
  display: none !important;
}
