:root { 
  // заглавный цвет в приложение, используется для заголовков, бордеры итд...
  --mat-custom-color-blue-indigo: #315470;

  // белый
  --mat-custom-color-white: #ffffff;

  // подложка степера и серых элементов
  --mat-custom-color-background: #f0f0f0;

  //самый темный цвет в проекте практически черный
  --mat-custom-color-darkest: #262626;

  // цвет предупреждения
  --mat-custom-color-orange-light: #ff890c;

  // предупреждение
  --mat-custom-color-red: #d0021b;

  // цвет маркера
  --mat-custom-color-blue-light: #62b5e5;

  // цвет текста серый
  --mat-custom-color-gray: #75787b;

  //светло серый
  --mat-custom-color-gray-brightest: #eaeef1;
  
  //светло серый
  --mat-custom-color-light-gray: #c5c6c8;

  // цвет текста степера
  --mat-custom-gray-bolder: #212529;

  --mat-custom-icon-color-warning: #ce0058;

  // цвет бекграунда иконок услуг
  --mat-custom-icon-color-red: #ee2737;
}

// берем за основу палитру и пееропределяем нужный нам цвет, 500: #17bb4f
$mat-green: (
  50: #e2f4e8,
  100: #b6e4c5,
  200: #85d39f,
  300: #54c179,
  400: #2fb35c,
  500: #0aa63f,
  600: #099e39,
  700: #079531,
  800: #058b29,
  900: #037b1b,
  A100: #a8ffb4,
  A200: #75ff88,
  A400: #42ff5d,
  A700: #29ff47,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

// берем за основу палитру и переопределяем нужный нам цвет, #ff890c,
$mat-orange: (
  50: #fbeee0,
  100: #f4d4b3,
  200: #edb880,
  300: #e59b4d,
  400: #e08526,
  500: #ff890c,
  600: #d66800,
  700: #d05d00,
  800: #cb5300,
  900: #c24100,
  A100: #fff0eb,
  A200: #ffcab8,
  A400: #ffa585,
  A700: #ff926b,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

// берем за основу палитру и переопределяем нужный нам цвет
// #f7142f 800,
$mat-red: (
  50: #f9e1e4,
  100: #f1b3bb,
  200: #e8818d,
  300: #de4e5f,
  400: #d7283d,
  500: #ce0058,
  600: #cb0218,
  700: #c40114,
  800: #d0021b,
  900: #b30108,
  A100: #ffdcdd,
  A200: #ffa9aa,
  A400: #ff7678,
  A700: #ff5d5f,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

//
$mat-indigo: (
  50: #e6eaee,
  100: #c1ccd4,
  200: #98aab8,
  300: #6f879b,
  400: #506e85,
  500: #315470,
  600: #2c4d68,
  700: #25435d,
  800: #1f3a53,
  900: #132941,
  A100: #7eb8ff,
  A200: #4b9cff,
  A400: #1880ff,
  A700: #0072fd,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$mw576: "(min-width: 576px)";
$mw992: "(min-width: 992px)";
$mw1270: "(min-width: 1270px)";

/* new MIN*/
$mw321: "(min-width: 321px)";
$mw480: "(min-width: 480px)";
$mw640: "(min-width: 640px)";
$mw768: "(min-width: 768px)";
$mw1024: "(min-width: 1024px)";
$mw1230: "(min-width: 1230px)";
$mw1280: "(min-width: 1280px)";
$mw1366: "(min-width: 1366px)";
$mw1600: "(min-width: 1600px)";
$mw1920: "(min-width: 1920px)";


$mxw576: "(max-width: 576px)";
$mxw992: "(max-width: 992px)";
$mxw1270: "(max-width: 1270px)";
$maxw600: "(max-width: 600px)";

/* new MAX*/
$mxw480: "(max-width: 480px)";
$mxw640: "(max-width: 640px)";
$mxw768: "(max-width: 768px)";
$maxw1024: "(max-width: 1024px)";
$mxw1280: "(max-width: 1280px)";
$mxw1366: "(max-width: 1366px)";
$mxw1600: "(max-width: 1600px)";
$mxw1920: "(max-width: 1920px)";