.mat-mdc-checkbox{
  --mdc-checkbox-selected-icon-color: var(--mat-custom-color-blue-indigo);
  --mdc-checkbox-selected-pressed-icon-color: var(--mat-custom-color-blue-indigo);
  --mdc-checkbox-state-layer-size: 16px;
  --mat-checkbox-touch-target-display: none;

  .mdc-form-field {
    --mdc-form-field-label-text-size: 13px;

    .mdc-label {
      padding-left: 10px;
    }
  }

  .mdc-checkbox {
    flex: 0 0 16px;
    width: 16px;
    height: 16px;

    &__ripple {
      display: none;
    }
    
    .mdc-checkbox__background {
      top: 0;
      left: 0;
      width: 16px;
      height: 16px;
      border: 1px solid var(--mat-custom-color-blue-indigo);
      border-radius: 3px;
    }

    &__checkmark {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 10px;
    }
  }
}