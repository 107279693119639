.datepicker {
  background-color: var(--mat-custom-color-brightest);
}
mat-form-field {

  &.mat-form-field-invalid {
    border: 1px solid var(--mat-custom-color-red);
  }
  
  // стили кнопки открывания календаря
  .mat-datepicker-toggle {
    opacity: 1;
    position: absolute;
    right: 0;
    top: 50%;
    height: 100%;
    transform: translateY(-50%);
    border-radius: 6px;

    .mat-mdc-icon-button {
      height: 100%;
      width: 16px;
      line-height: 16px;
      opacity: 0.7;
      padding: 0;
      --mdc-icon-button-icon-size: 16px;
    }
  }
}

// окрашиваем в белый mat-date-range-input
.mat-date-range-input {
  background-color: var(mat-custom-color-brightest);
  padding-right: 27px;
  border-radius: 6px;
}

mat-date-range-picker, mat-date-picker{
  width: 100%;
  height: 100%;
  position: absolute;
}

mat-date-range-input,
.dateRange {
  border-radius: 6px;
  --mat-form-field-filled-with-label-container-padding-top: 0;
  --mat-form-field-filled-with-label-container-padding-bottom: 0;
  --mat-form-field-container-text-line-height: none;
  // стили date-range

  .mat-date-range-input-container {
    height: 100%;

    .mat-date-range-input-start-wrapper {
      min-width: 68px;
      max-width: 75px;
      overflow: hidden;
      display: inline-block;
      height: 100%;
      input {
        height: 100%;
      }
    }

    .mat-date-range-input-separator {
      height: 27px;
      margin: 0 7px;
      padding-top: 4px;
      display: inline-block;
    }

    .mat-date-range-input-end-wrapper {
      max-width: 75px;
      overflow: hidden;
      display: inline-block;
      height: 100%;
      input {
        height: 100%;
      }
    }

    input {
      border: none !important;
      position: relative !important;

      width: auto;
    }
  }
}

// скрываем кнопку закрыть календарь
.mat-datepicker-close-button {
  display: none !important;
}

// стили страницы
.range-date {
  min-height: 36px;

  position: relative;
}

// настройки для тайм пикера
.datepicker-timepicker {
  max-width: 100px !important;
}

// стили для преиода с раздельными полями
// скрываем сепоратор, черточку
.no-separator {
  .mat-date-range-input-separator {
    display: none !important;
  }
}
// скрываем поле периода
.hide-field-end {
  .mat-date-range-input-end-wrapper {
    display: none !important;
    opacity: 0;
    width: 0px !important;
  }
}
.hide-field-start {
  .mat-date-range-input-start-wrapper {
    display: none !important;
    opacity: 0;
    width: 0px !important;
  }
}

.mat-mdc-icon-button .mat-mdc-button-persistent-ripple {
  display: none;
}

.mat-calendar-controls {
  justify-content: space-between;

  .mat-calendar-spacer {
    display: none;
  }

  .mat-calendar-period-button {
    order: 2;
  }

  .mat-calendar-previous-button {
    order: 1;
  }

  .mat-calendar-next-button {
    order: 3;
  }
}

.mat-calendar-table-header {
  text-transform: uppercase;
  font-size: 13px;

  tr {
    &:nth-last-child(1) {
      display: none;
    }
    
    th {
      padding: 0 0 15px 0;
      color: var(--mat-custom-color-gray);

      &:nth-last-child(1), &:nth-last-child(2) {
        color: var(--mat-custom-icon-color-red);
      }
    }
  }
}

.mat-calendar-body {
  tr {
    &:nth-child(1) .mat-calendar-body-label {
      visibility: hidden;
    }

    &:nth-child(1) .mat-calendar-body-label[colspan="7"] {
      display: none;
    }
  }

  .mat-calendar-body-cell:focus .mat-focus-indicator::before {
    content: none;
  }

  .mat-calendar-body-cell{
    --mat-datepicker-calendar-date-selected-state-background-color: #315470;
    --mat-datepicker-calendar-date-hover-state-background-color: rgba(49, 84, 112, 0.1);

    &-content {
      border-radius: 10px;
      color: var(--mat-custom-color-darkest);
      border: 0;
      font-size: 15px;
    }
  }

  .mat-calendar-body-preview-end .mat-calendar-body-cell-preview {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}

mat-month-view {
  --mat-datepicker-calendar-date-focus-state-background-color: transparent;
  
  .mat-calendar-body-cell-content.mat-calendar-body{
    &-today {
      background: transparent;
      color: var(--mat-custom-color-blue-indigo);
      font-weight: 700;
    }
    
    &-selected {
      background: var(--mat-custom-color-blue-indigo);
      color: #fff;
      font-weight: normal;
    }
  }
}

mat-year-view, mat-multi-year-view {
  --mat-datepicker-calendar-date-focus-state-background-color: #315470;
  
  .mat-calendar-body-cell-container {
    height: 50px;
    padding: 0 !important;
  }

  .mat-calendar-body-cell-content{
    background: var(--mat-custom-color-background);
  }

  .mat-calendar-body-active {
    .mat-calendar-body-cell-content{
      background: var(--mat-custom-color-blue-indigo);
      color: #fff;
      font-weight: normal;
    }
  }
}